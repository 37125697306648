import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './common/app/App';
import './common/i18n/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Application from './common/application/application';
import setupUncaughtErrorHandling from './common/error_boundary/setup_uncaught_error_handling';
import i18n from './common/i18n/i18n';
import de_common_messages from './common/messages/de.json';
import en_common_messages from './common/messages/en.json';
import fr_common_messages from './common/messages/fr.json';
import tr_common_messages from './common/messages/tr.json';
import { FirebaseNotificationService } from './common/notification/notification_service';
import { RenderType } from './common/svg_image_renderer/svg_img_renderer';
import { FirebaseTagService } from './common/tag/tag_service';
import Help from './help/help';
import MosaicItemService from './item_service/mosaic_item_service';
import de_messages from './messages/de.json';
import en_messages from './messages/en.json';
import fr_messages from './messages/fr.json';
import tr_messages from './messages/tr.json';
import theme from './theme';
import { mosaicPreferencesList } from './preferences/preferences';

setupUncaughtErrorHandling();

i18n
  .addResourceBundle('en', 'translation', en_common_messages)
  .addResourceBundle('de', 'translation', de_common_messages)
  .addResourceBundle('tr', 'translation', tr_common_messages)
  .addResourceBundle('fr', 'translation', fr_common_messages)
  .addResourceBundle('en', 'translation', en_messages, true, true)
  .addResourceBundle('de', 'translation', de_messages, true, true)
  .addResourceBundle('tr', 'translation', tr_messages, true, true)
  .addResourceBundle('fr', 'translation', fr_messages, true, true);

const root = createRoot(document.getElementById('root')!);

const application = new Application({
  languages: ['en', 'de', 'fr', 'tr'],
  defaultRenderType: RenderType.svg,
  additionalAuthDomains: new Set(),
  itemService: (application) => new MosaicItemService(application),
  notificationService: (_) => new FirebaseNotificationService('wuerfelmosaik'),
  tagService: (_) => new FirebaseTagService(),
  preferences: mosaicPreferencesList,
});
root.render(
  <React.StrictMode>
    <Suspense fallback={'...'}>
      <App help={<Help />} theme={theme} application={application} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
