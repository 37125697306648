import { ReactNode, Ref } from 'react';
import BaseFirebaseItemService from '../common/item_service/base_firebase_item_service';

import * as fs from 'firebase/firestore';
import i18next from 'i18next';
import Application from '../common/application/application';
import { ItemHandle } from '../common/item/item_handle';
import {
  ListConstraint,
  StaticOptionsListConstraintMeta,
  ValueListConstraint,
} from '../common/list/filter';
import { FirebaseTagService } from '../common/tag/tag_service';
import { ViewDetail } from '../common/view/view';
import { EditMosaic, NewMosaic } from '../edit/edit';
import { Cube, Pattern } from '../model/cube';
import { Mosaic as MosaicModel } from '../model/mosaic';
import Mosaic from '../mosaic/mosaic';
import { findThemeById } from '../theme/theme';

export default class MosaicItemService extends BaseFirebaseItemService<MosaicModel> {
  constructor(application: Application) {
    super('mosaic', 'likes', 'mosaic_id', application);
  }

  override async deserializeItem(
    id: string,
    data: { [fieldPath: string]: any }
  ): Promise<MosaicModel> {
    return await deserializeMosaic(
      id,
      data,
      this.application.tagService as FirebaseTagService
    );
  }

  override serializeItem(
    item: MosaicModel,
    uid: string
  ): { [fieldPath: string]: any } {
    return serializeMosaic(item, uid);
  }

  override getItemViewOverview(item: MosaicModel): ReactNode {
    return <Mosaic mosaic={item} />;
  }

  override getDefaultListConstraints(
    languages: string[],
    uid?: string | undefined
  ): ListConstraint[] {
    return [
      ...super.getDefaultListConstraints(languages, uid),
      new ValueListConstraint(
        undefined,
        new StaticOptionsListConstraintMeta<number>(
          (size) => (size ? [fs.where('size', '==', size)] : []),
          (_) => [],
          Array.from({ length: 9 }, (v, k) => k + 2),
          (size) => `${size} x ${size}`,
          undefined,
          i18next.t('filter.mosaicSize.placeholder') ?? undefined
        )
      ),
    ];
  }

  override getItemViewDetail(
    item: MosaicModel,
    className: string,
    itemHandle: Ref<ItemHandle>
  ): ViewDetail {
    return {
      detailView: (
        <div className={`${className} mosaicPrintStyle`}>
          <style>{`
          @media print {
            @page {
              size: auto;
              margin: 0mm;
            }

            body {
              position: relative;
            }
            
            body * {
              visibility: hidden;
            }

            .mosaicPrintStyle * {
              visibility: visible;
            }
            
            .mosaicPrintStyle {
              visibility: visible;
              position: absolute;
              top: 0;
              left: 0;
              margin: 1cm;
              width: ${item.widthMM}mm !important;
            }
          }
        `}</style>
          <Mosaic mosaic={item} ref={itemHandle} />
        </div>
      ),
      additionalFunctions: () => [],
    };
  }

  override getItemViewEdit(): ReactNode {
    return <EditMosaic />;
  }

  override getItemViewNew(): ReactNode {
    return <NewMosaic />;
  }
}

export function serializeMosaic(
  mosaic: MosaicModel,
  uid: string
): { [fieldPath: string]: any } {
  const rows = mosaic.rows
    .flat()
    .map((cube) => `${cube.pattern.id}:${cube.rotation}`)
    .join(',');
  return {
    rows: rows,
    size: mosaic.size,
    version: 1,
    uid: uid,
    public: mosaic.public,
    approved: mosaic.approved,
    tags: FirebaseTagService.toTagDocumentReferences(mosaic.tags),
    theme: mosaic.theme.id,
  };
}

export async function deserializeMosaic(
  id: string,
  data: { [fieldPath: string]: any },
  tagService: FirebaseTagService
): Promise<MosaicModel> {
  const cubes = (data.rows as string).split(',');
  const size = data.size as number;
  if (cubes.length !== size * size) throw new Error(data.rows as string);
  const rows = [];
  for (let rix = 0; rix < size; rix++) {
    rows.push(
      cubes.slice(rix * size, (rix + 1) * size).map((cubeString) => {
        const parts = cubeString.split(':');
        return new Cube(
          Pattern.values.find((p) => p.id === parts[0]) ?? Pattern.empty,
          parseInt(parts[1])
        );
      })
    );
  }
  return new MosaicModel(
    id,
    size,
    rows,
    data.uid as string,
    data.public as boolean,
    data.approved as boolean,
    findThemeById(data.theme),
    new Set(data.likers),
    await tagService.fromTagDocumentReferences(data.tags)
  );
}
