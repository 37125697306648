import { User } from 'firebase/auth';
import { Item } from '../common/model/item';
import Tag from '../common/tag/tag';
import { defaultTheme, Theme } from '../theme/theme';
import { Cube } from './cube';

const printCubeSideInMillimeter = 20;

export class Mosaic extends Item<Mosaic> {
  readonly size: number;
  readonly rows: Cube[][];
  readonly theme: Theme;

  constructor(
    id: string | undefined,
    size: number,
    rows: Cube[][],
    uid: string | undefined,
    isPublic: boolean,
    approved: boolean,
    theme: Theme,
    likers: Set<String>,
    tags: Set<Tag>
  ) {
    super('mosaic', id, uid, isPublic, approved, likers, tags);
    this.size = size;
    this.rows = rows;
    this.theme = theme;
  }

  override get isComplete() {
    return !this.rows.flat().some((c) => c.isEmpty);
  }

  static empty(size: number, uid: string) {
    return new Mosaic(
      undefined,
      size,
      Array(size).fill(Array(size).fill(Cube.empty)),
      uid,
      true,
      false,
      defaultTheme,
      new Set([]),
      new Set([])
    );
  }

  static _fill(
    x: number,
    y: number,
    cubeToPaint: Cube,
    cube: Cube,
    rows: Cube[][]
  ) {
    if (x < 0 || x >= rows[0].length) {
      return;
    }
    if (y < 0 || y >= rows.length) {
      return;
    }
    if (!rows[y][x].equals(cubeToPaint)) {
      return;
    }
    rows[y][x] = cube;
    Mosaic._fill(x - 1, y, cubeToPaint, cube, rows);
    Mosaic._fill(x + 1, y, cubeToPaint, cube, rows);
    Mosaic._fill(x, y - 1, cubeToPaint, cube, rows);
    Mosaic._fill(x, y + 1, cubeToPaint, cube, rows);
  }

  hasCube(x: number, y: number) {
    return !this.rows[y][x].isEmpty;
  }

  getCube(x: number, y: number) {
    return this.rows[y][x];
  }

  inRange(x: number, y: number) {
    return x >= 0 && x < this.size && y >= 0 && y < this.size;
  }

  setCube(x: number, y: number, cube: Cube) {
    const rows = this.rows.map((inner) => inner.slice());
    rows[y][x] = cube;
    return new Mosaic(
      this.id,
      this.size,
      rows,
      this.uid,
      this.public,
      this.approved,
      this.theme,
      this.likers,
      this.tags
    );
  }

  fill(x: number, y: number, cube: Cube) {
    const cubeToPaint = this.getCube(x, y);
    if (cubeToPaint.equals(cube)) {
      return this;
    }
    const rows = this.rows.map((inner) => inner.slice());
    Mosaic._fill(x, y, cubeToPaint, cube, rows);
    return new Mosaic(
      this.id,
      this.size,
      rows,
      this.uid,
      this.public,
      this.approved,
      this.theme,
      this.likers,
      this.tags
    );
  }

  setTheme(theme: Theme) {
    return new Mosaic(
      this.id,
      this.size,
      this.rows,
      this.uid,
      this.public,
      this.approved,
      theme,
      this.likers,
      this.tags
    );
  }

  override setPublic(isPublic: boolean) {
    if (this.public === isPublic) {
      return this;
    }
    return new Mosaic(
      this.id,
      this.size,
      this.rows,
      this.uid,
      isPublic,
      this.approved,
      this.theme,
      this.likers,
      this.tags
    );
  }

  override setApproved(approved: boolean) {
    if (this.approved === approved) {
      return this;
    }
    return new Mosaic(
      this.id,
      this.size,
      this.rows,
      this.uid,
      this.public,
      approved,
      this.theme,
      this.likers,
      this.tags
    );
  }

  override async addLiker(user: User) {
    if (this.likers.has(user.uid)) {
      return this;
    }

    return new Mosaic(
      this.id,
      this.size,
      this.rows,
      this.uid,
      this.public,
      this.approved,
      this.theme,
      new Set([...Array.from(this.likers), user.uid]),
      this.tags
    );
  }

  override async removeLiker(user: User) {
    if (!this.likers.has(user.uid)) {
      return this;
    }

    return new Mosaic(
      this.id,
      this.size,
      this.rows,
      this.uid,
      this.public,
      this.approved,
      this.theme,
      new Set(Array.from(this.likers).filter((l) => l !== user.uid)),
      this.tags
    );
  }

  override copyForCurrentUser(uid: string): Mosaic {
    return new Mosaic(
      undefined,
      this.size,
      this.rows,
      uid,
      true,
      false,
      this.theme,
      new Set([]),
      this.tags
    );
  }

  override setTags(tags: Set<Tag>): Mosaic {
    if (tags === this.tags) {
      return this;
    }
    return new Mosaic(
      this.id,
      this.size,
      this.rows,
      this.uid,
      this.public,
      this.approved,
      this.theme,
      this.likers,
      tags
    );
  }

  override get widthMM(): number {
    return printCubeSideInMillimeter * this.size;
  }

  override get heightMM(): number {
    return printCubeSideInMillimeter * this.size;
  }
}
