import {
  Preference,
  PreferenceType,
  PreferenceValueType,
} from '../common/preferences/preferences';
import usePreferenceValue from '../common/preferences/preferences_hook';

const renderInnerGridPreference = new Preference<boolean>(
  'mosaic_render_inner_grid',
  PreferenceType.rendering,
  PreferenceValueType.boolean,
  true
);

export const mosaicPreferencesList: Set<Preference<any>> = new Set([
  renderInnerGridPreference,
]);

export function useMosaicRenderInnerGridPreference(): boolean {
  return usePreferenceValue(renderInnerGridPreference);
}
