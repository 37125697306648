import { Mosaic as MosaicModel } from '../model/mosaic';
import { forwardRef, memo, Ref, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Cube } from '../cube/cube';
import { svgStyles } from '../svg/styles';
import { ItemHandle } from '../common/item/item_handle';
import SvgImgRenderer, {
  RenderType,
} from '../common/svg_image_renderer/svg_img_renderer';
import ApplicationContext from '../common/application/context';
import Application from '../common/application/application';
import { useMosaicRenderInnerGridPreference } from '../preferences/preferences';

interface MosaicProps {
  mosaic: MosaicModel;
  onCubeClick?: (x: number, y: number) => void;
  render?: RenderType;
  applyUserPreferences?: boolean;
}

function _Mosaic(
  {
    mosaic,
    onCubeClick = undefined,
    render,
    applyUserPreferences = true,
  }: MosaicProps,
  ref: Ref<ItemHandle>
) {
  const application = useContext<Application>(ApplicationContext);
  const mosaicInnerGridPreference = useMosaicRenderInnerGridPreference();

  const strokeWidth = 2;
  const outerStrokeWidth = strokeWidth;
  // Make sure the mosaic is more or less 800 units wide/high,
  // but more imporantly that cubeSize is a whole number.
  // Otherwise the the cubes don't render nice in the downloaded PNG/PDF
  // if the inner grid is absent.
  const cubeSize = Math.floor(800 / mosaic.size);
  const svgSize = cubeSize * mosaic.size + outerStrokeWidth;
  const innerStrokeWidth =
    !applyUserPreferences || mosaicInnerGridPreference ? strokeWidth : 0;
  const offset = outerStrokeWidth - innerStrokeWidth / 2;

  const viewBox = `0 0 ${svgSize} ${svgSize}`;
  const [svgIdPrefix] = useState(uuidv4());

  const cubes = [];
  const grid = [];

  for (let y = 0; y < mosaic.rows.length; y++) {
    for (let x = 0; x < mosaic.rows[y].length; x++) {
      cubes.push(
        <Cube
          key={`${x},${y}`}
          cube={mosaic.rows[y][x]}
          x={offset + x * cubeSize}
          y={offset + y * cubeSize}
          ix={x}
          iy={y}
          onCubeClick={onCubeClick}
          cubeSize={cubeSize}
          strokeWidth={0}
          svgIdPrefix={svgIdPrefix}
        />
      );
    }
  }

  if (outerStrokeWidth > 0) {
    grid.push(
      <rect
        key={'outline'}
        x={outerStrokeWidth / 2}
        y={outerStrokeWidth / 2}
        width={svgSize - outerStrokeWidth}
        height={svgSize - outerStrokeWidth}
        fill="none"
        stroke="black"
        strokeWidth={outerStrokeWidth}
      />
    );
  }
  if (innerStrokeWidth > 0) {
    for (let i = 1; i < mosaic.size; i++) {
      grid.push(
        <line
          key={`h-grid-line-${i}`}
          x1={offset}
          y1={offset + i * cubeSize}
          x2={svgSize - offset}
          y2={offset + i * cubeSize}
          stroke="black"
          strokeWidth={innerStrokeWidth}
        />
      );
      grid.push(
        <line
          key={`v-grid-line-${i}`}
          x1={offset + i * cubeSize}
          y1={offset}
          x2={offset + i * cubeSize}
          y2={svgSize - offset}
          stroke="black"
          strokeWidth={innerStrokeWidth}
        />
      );
    }
  }

  return (
    <SvgImgRenderer
      ref={ref}
      item={mosaic}
      render={render ?? application.defaultRenderType}
      fileNamePrefix="mosaic"
      viewBox={viewBox}
      width={svgSize}
      height={svgSize}>
      {svgStyles(mosaic.theme, svgIdPrefix)}
      {cubes}
      {grid}
    </SvgImgRenderer>
  );
}

export const Mosaic = memo(forwardRef(_Mosaic));
export default Mosaic;
